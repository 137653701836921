import { FC, ReactNode } from "react";
import classNames from "classnames";

import { OutcomeType } from "@lib/data/schemas/outcome";

import { OUTCOME_ICONS } from "./utils";

interface OutcomeIconProps {
  outcome: OutcomeType;
  disabled?: boolean;
  withLabel?: boolean;
  className?: string;
  iconClassName?: string;
  info?: ReactNode;
  rightIcon?: ReactNode;
}

export const OutcomeIconLabel: FC<OutcomeIconProps> = ({
  outcome,
  className,
}) => {
  const color = outcome.color.foreground;
  const title = outcome.title;
  return (
    <div style={{ color }} className={classNames("font-medium", className)}>
      {title}
    </div>
  );
};

interface OutcomeIconInfoProps {
  title: string;
  icon?: ReactNode;
  disabled?: boolean;
  className?: string;
}

export const OutcomeIconInfo: FC<OutcomeIconInfoProps> = ({
  title,
  icon,
  disabled = false,
  className,
}) => {
  return (
    <div
      className={classNames(
        "flex items-center gap-1 min-w-0 text-action-500 ",
        className
      )}
    >
      {icon}
      <p
        className={classNames(
          "text-xs truncate min-w-0",
          disabled && "text-grey-500"
        )}
      >
        {title}
      </p>
    </div>
  );
};

const OutcomeIcon: FC<OutcomeIconProps> = ({
  outcome,
  disabled = false,
  withLabel = false,
  className,
  iconClassName,
  info,
  rightIcon,
}) => {
  const Icon = OUTCOME_ICONS[outcome.icon];
  return (
    <div className={classNames("flex gap-2 items-center min-w-0")}>
      <div
        className={classNames(
          "flex",
          outcome.id === "none" &&
            "border border-dashed rounded-full border-grey-500"
        )}
      >
        <div
          style={{
            backgroundColor: outcome.color.background,
            color: outcome.color.foreground,
          }}
          className={classNames(
            "rounded-full h-full w-full flex justify-center items-center",
            className,
            disabled && "!bg-grey-900"
          )}
        >
          <Icon
            className={classNames(iconClassName, disabled && "text-grey-800")}
          />
        </div>
      </div>

      {withLabel && (
        <div className="min-w-0">
          <OutcomeIconLabel
            outcome={outcome}
            className={classNames(disabled && "!text-grey-800")}
          />
          {info}
        </div>
      )}
      {rightIcon && (
        <div className="flex flex-1 items-center justify-end">{rightIcon}</div>
      )}
    </div>
  );
};

export default OutcomeIcon;
