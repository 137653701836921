import { FC, FormEvent, useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { PackageSchedulerType } from "api-services/definitions/package-instances";
import { isEmpty } from "lodash";

import { useAuth } from "@contexts/auth";
import useSnackbar from "@hooks/use-snackbar";
import { useUpdateAppointmentsCache } from "@hooks/useUpdateAppointmentsCache";
import { AppointmentType } from "@lib/data/schemas/appointment";
import { PackageInstanceType } from "@lib/data/schemas/package-instance";
import { PackageType } from "@lib/data/schemas/packages";

import EventAfterIcon from "@components/Icons/EventAfterIcon";
import SmallModal from "@components/Modals/SmallModal";

import AssignPackageSchedulerFormFields from "./AssignPackageSchedulerFormFields";
type FormDataType = {
  packageInstanceId: string;
  schedulerId: string;
  cycle: number;
};

interface AssignPackageModalProps {
  show: boolean;
  toggleShow: (val: boolean) => void;
  appointment: AppointmentType;
  packages: PackageType[];
  packageInstances: Array<
    PackageInstanceType & {
      ownerId?: string;
    }
  >;
  packageSchedulers: PackageSchedulerType[];
  linkAppt: (
    packageInstanceId: string,
    packageInstanceOwnerId: string | undefined,
    appointmentId: string,
    schedulerId: string,
    cycle: number
  ) => Promise<void>;
  packageAppointments?: AppointmentType[];
}

const AssignPackageSchedulerModal: FC<AssignPackageModalProps> = ({
  show,
  toggleShow,
  appointment,
  packages,
  packageInstances,
  packageSchedulers,
  linkAppt,
  packageAppointments,
}) => {
  const { oid } = useAuth();
  const snackbar = useSnackbar();
  const formMethods = useForm<FormDataType>({
    mode: "onTouched",
    shouldUnregister: false,
    defaultValues: {
      packageInstanceId: "",
      schedulerId: "",
    },
  });
  const { watch, handleSubmit } = formMethods;
  const [loading, setLoading] = useState<boolean>(false);
  const packageInstanceId = watch("packageInstanceId");
  const schedulerId = watch("schedulerId");
  const appointmentId = appointment?.id;

  const { onUpdateAppointment } = useUpdateAppointmentsCache();

  const filteredSchedulers = useMemo(() => {
    return packageSchedulers?.filter((scheduler) => {
      return scheduler.packageInstanceId === packageInstanceId;
    });
  }, [packageSchedulers, packageInstanceId]);

  const packageInstance = packageInstances.find(
    (packageObject) => packageObject.id === packageInstanceId
  );

  const canAssignToPackage =
    packageInstanceId && schedulerId && filteredSchedulers?.length;

  const onSubmit = async (data: FormDataType, e: FormEvent) => {
    setLoading(true);

    const packageInstanceOwnerId =
      packageInstance?.ownerId ?? appointment.contactId;

    if (!oid || !packageInstanceOwnerId) return;

    e.preventDefault();
    try {
      await linkAppt(
        data.packageInstanceId,
        packageInstance?.ownerId,
        appointmentId,
        data.schedulerId,
        data.cycle
      );
      await onUpdateAppointment(appointmentId);
      snackbar.showMessage("Appointment assigned to package");
    } catch (e) {
      snackbar.showWarning(
        "Error occurred",
        "Assigning package failed, please try again!"
      );
    } finally {
      setLoading(false);
      toggleShow(false);
    }
  };

  return (
    <SmallModal
      show={show}
      toggleShow={toggleShow}
      title="Add appointment to package"
      description="Select the scheduler this appointment will be assigned to. It will update the specific scheduler and package counter."
      icon={EventAfterIcon}
      iconColor="green"
      heapModalName="assign_package_scheduler_modal"
      onActionText="Assign to package"
      onAction={handleSubmit(onSubmit)}
      onActionLoading={loading || formMethods.formState.isSubmitting}
      isActionDisabled={
        !canAssignToPackage || !isEmpty(formMethods.formState.errors)
      }
      isCloseAfterPrimaryActionDisabled
    >
      <FormProvider {...formMethods}>
        <AssignPackageSchedulerFormFields
          packages={packages}
          packageInstances={packageInstances}
          packageSchedulers={packageSchedulers}
          packageAppointments={packageAppointments}
          appointment={appointment}
        />
      </FormProvider>
    </SmallModal>
  );
};

export default AssignPackageSchedulerModal;
