import { useMemo } from "react";
import { Calendar, CalendarIntegration } from "@practice/sdk";

import { useAuth } from "@contexts/auth";
import { getAllCalendarAccounts } from "@lib/utils/google-calendars";

import { useHasFullAccess } from "./use-organization-account";

type UseGetGoogleCalendarsResponse = {
  accounts: CalendarIntegration[];
  defaultWriteCalendar: Calendar | null;
};

type UseGetGoogleCalendarsType = (
  accountOnly?: boolean
) => UseGetGoogleCalendarsResponse;

export const useGetCalendarsForMember: (
  memberId: string
) => UseGetGoogleCalendarsResponse = (memberId) => {
  const {
    organizationAccounts: { integrations },
  } = useAuth();
  return useMemo(() => {
    if (!integrations?.calendars?.length)
      return { accounts: [], defaultWriteCalendar: null };
    return getAllCalendarAccounts(
      integrations.calendars.filter(
        (calendar) => calendar.memberId === memberId
      )
    );
  }, [integrations?.calendars, memberId]);
};

export const useGetCalendars: UseGetGoogleCalendarsType = (
  accountOnly = false
) => {
  const {
    organizationAccounts: { integrations },
    aid,
  } = useAuth();
  return useMemo(() => {
    if (!integrations?.calendars?.length)
      return { accounts: [], defaultWriteCalendar: null };
    if (accountOnly)
      return getAllCalendarAccounts(
        integrations.calendars.filter((calendar) => calendar.memberId === aid)
      );
    return getAllCalendarAccounts(integrations.calendars);
  }, [integrations?.calendars, accountOnly, aid]);
};

export const useGetAvailabilityFormCalendars = () => {
  const { accounts: allAccounts } = useGetCalendars();
  const hasFullAccess = useHasFullAccess();
  const { aid } = useAuth();

  return useMemo(() => {
    const accounts = allAccounts.filter(
      (account) => hasFullAccess || account.memberId === aid
    );
    const defaultIntegration =
      accounts.find((acc) => acc.memberId === aid) || accounts[0];

    const defaultWriteCalendar =
      defaultIntegration?.calendars.find((cal) => cal.isDefaultCalendar) ||
      defaultIntegration?.calendars[0];

    return {
      accounts,
      defaultWriteCalendar,
      allAccounts,
    };
  }, [aid, allAccounts, hasFullAccess]);
};

export function useGetZoomAccounts(accountOnly = false) {
  const {
    organizationAccounts: { integrations },
    aid,
  } = useAuth();
  return useMemo(() => {
    if (!integrations?.zoom?.length) return { accounts: [] };
    if (accountOnly)
      return {
        accounts: integrations.zoom.filter((zoom) => zoom.memberId === aid),
      };
    return { accounts: integrations.zoom };
  }, [integrations?.zoom, accountOnly, aid]);
}
